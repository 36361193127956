export const uiActions = {
  headerSize: "UPDATE_HEADER_SIZE",
  footerSize: "UPDATE_FOOTER_SIZE",
  scroll: "UPDATE_SCROLL",
  width: "UPDATE_WIDTH",
  height: "UPDATE_WIDTH",
  desktopCollapse: "UPDATE_DESKTOP_COLLAPSE",
  mobileDrawer: "UPDATE_MOBILE_DRAWER",
};

export const apiActions = {
  UserData: "UPDATE_USER_DATA",
  Brands: "UPDATE_BRANDS",
  Category: "UPDATE_CATEGORY",
  SubCategory: "UPDATE_SUBCATEGORY",
  Scent: "UPDATE_SCENT",
  AppLoading: "UPDATE_APP_LOADING",
};
