import { URLS } from "../constants/nav";
import createBaseApi from "./base";

const createUserApi = () => {
  const userApi = createBaseApi(URLS.backendUsers, {});
  const awsApi = createBaseApi(URLS.backendAws, {});
  return {
    login: (email, password) =>
      userApi.post("/login", { email, password }).then((res) => res.data),
    getData: () => userApi.get(`/get/data?portal=admin`),
    getProfile: () => userApi.get("/profile"),
    logout: () => userApi.get("/logout").then((res) => res.data),
    logoutAll: () => userApi.post("/logout").then((res) => res.data),
    updatePassword: (body) =>
      userApi.post("/update-password", body).then((res) => res.data),
    updateProfile: (body) =>
      userApi.post("/profile", body).then((res) => res.data),
    addUser: (body) => userApi.post("", body).then((res) => res.data),
    search: ({ keywords, role, skip, limit }) =>
      userApi.get(
        `?keywords=${keywords}&role=${role}&skip=${skip}&limit=${limit}`
      ),
    getPolicy: ({ fileName, mime, type }) =>
      awsApi
        .post("/get-policy", { fileName, mime, type })
        .then((res) => res.data),
  };
};

const userApi = createUserApi();

export default userApi;
