import { apiActions } from "../constants/redux";

export const setUserData = (data) => ({ type: apiActions.UserData, data });

export const setBrands = (data) => ({ type: apiActions.Brands, data });

export const setCategories = (data) => ({ type: apiActions.Category, data });

export const setScents = (data) => ({ type: apiActions.Scent, data });

export const setSubCategory = (data) => ({
  type: apiActions.SubCategory,
  data,
});

export const setAppLoading = (data) => ({ type: apiActions.AppLoading, data });
