import React, { StrictMode, Suspense } from "react";
import "bulma/bulma.sass";
import "./styles/global.scss";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./reducer";
import { App } from "./app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Spin } from "antd";
import Title from "antd/es/typography/Title";
import { LoadingOutlined } from "@ant-design/icons";

const client = new QueryClient();

const Index = () => {
  return (
    <Suspense
      fallback={
        <div
          className="is-flex is-justify-content-center is-align-items-center"
          style={{ width: "100vw", height: "100vh" }}
        >
          <Spin
            size="large"
            indicator={<LoadingOutlined />}
            tip={
              <Title className="m-0 mt-2" level={4}>
                Loading App...
              </Title>
            }
          />
        </div>
      }
    >
      <StrictMode>
        <QueryClientProvider client={client}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </QueryClientProvider>
      </StrictMode>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
