import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import userApi from "../../api/users";

export const Login = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (formData) => {
    setLoading(false);
    userApi
      .login(formData.email, formData.password)
      .then((res) => {
        if (!res.success) {
          notification.warning({ message: res.msg });
        } else {
          window.localStorage.setItem("token", res.token);
          window.location.reload();
        }
      })
      .catch((err) =>
        notification.error({ message: "Error while reaching server!" })
      )
      .finally(() => setLoading(false));
  };

  return (
    <div
      className="is-flex is-justify-content-center is-align-items-center p-3"
      style={{ width: "100vw", height: "100vh", background: "#efefef" }}
    >
      <Card
        title={
          <Title level={4} className="m-0">
            Login to Admin
          </Title>
        }
        style={{ width: 600, maxWidth: "100%" }}
      >
        <Form layout="vertical" onFinish={onFinish} requiredMark={false}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: "email", required: true, message: "Enter valid email" },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
              type="primary"
            >
              Submit <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
