import { apiActions } from "../constants/redux";

const state = {
  UserData: {},
  AppLoading: false,
  Brands: [],
  Categories: [],
  Scents: [],
  SubCategories: [],
};

const apiReducer = (initialState = state, action) => {
  const { type, data } = action;
  switch (type) {
    case apiActions.UserData:
      return { ...initialState, UserData: data };
    case apiActions.Brands:
      return { ...initialState, Brands: data };
    case apiActions.Category:
      return { ...initialState, Categories: data };
    case apiActions.Scent:
      return { ...initialState, Scents: data };
    case apiActions.SubCategory:
      return { ...initialState, SubCategories: data };
    case apiActions.AppLoading:
      return { ...initialState, AppLoading: data };
    default:
      return initialState;
  }
};

export default apiReducer;
