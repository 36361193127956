import { config } from "./config";

export const URLS = {
  login: `${config.publicUrl}/login`,
  home: `${config.publicUrl}/`,
  product: `${config.publicUrl}/products`,
  orders: `${config.publicUrl}/orders`,
  users: `${config.publicUrl}/users`,
  profile: `${config.publicUrl}/profile`,
  blogs: `${config.publicUrl}/blogs`,
  scents: `${config.publicUrl}/admin/scents`,
  categories: `${config.publicUrl}/admin/categories`,
  subcategories: `${config.publicUrl}/admin/subcategories`,
  brands: `${config.publicUrl}/admin/brands`,

  backendUsers: `${config.apiBase}/users`,
  backendAws: `${config.apiBase}/aws`,
  backendAdmin: `${config.apiBase}/admin`,
  backendProduct: `${config.apiBase}/products`,
  backendOrders: `${config.apiBase}/orders`,
  backendBlogs: `${config.apiBase}/blogs`,
};
