import { uiActions } from "../constants/redux";

const state = {
  Scroll: 0,
  Height: window.innerHeight,
  Width: window.innerWidth,
  HeaderSize: 0,
  FooterSize: 0,
  DesktopCollapse: false,
  MobileDrawer: false,
};

const uiReducer = (initialState = state, action) => {
  const { type, data } = action;
  switch (type) {
    case uiActions.headerSize:
      return { ...initialState, HeaderSize: data };
    case uiActions.footerSize:
      return { ...initialState, FooterSize: data };
    case uiActions.width:
      return { ...initialState, Width: data };
    case uiActions.height:
      return { ...initialState, Height: data };
    case uiActions.scroll:
      return { ...initialState, Scroll: data };
    case uiActions.desktopCollapse:
      return { ...initialState, DesktopCollapse: data };
    case uiActions.mobileDrawer:
      return { ...initialState, MobileDrawer: data };
    default:
      return initialState;
  }
};

export default uiReducer;
