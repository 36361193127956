import axios from "axios";

const createBaseApi = (url, config = {}) => {
  const token = window.localStorage.getItem("token");
  const api = axios.create({
    baseURL: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
      ...config,
    },
    withCredentials: true,
  });
  return api;
};

export default createBaseApi;
