import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Modal, Spin } from "antd";
import Title from "antd/es/typography/Title";
import React, { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAppLoading, setUserData } from "./actions/api";
import userApi from "./api/users";
import { Login } from "./components/login";
import { get } from "lodash";
import { URLS } from "./constants/nav";

const Main = lazy(() => import("./main"));
const token = window.localStorage.getItem("token");

export const App = () => {
  const AppLoading = useSelector((state) => state.api.AppLoading);
  const UserData = useSelector((state) => state.api.UserData);
  const dispatch = useDispatch();
  const { pathname: path } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (UserData === {} && path !== "/") navigate("/");
  }, [path, navigate, UserData]);

  const { data, isError, isLoading, isFetching } = useQuery(
    ["get-data", token],
    () => userApi.getData(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const loading = isFetching || isLoading || AppLoading;

  if (isError) {
    Modal.confirm({
      content: "It seems that server is not reachable, Try again later",
      okText: "Refresh",
      okButtonProps: { icon: <ReloadOutlined /> },
      cancelButtonProps: { style: { display: "flex" } },
    });
    return <></>;
  }

  if (data) {
    const res = data.data;
    if (
      res.success &&
      get(res, "data") &&
      get(res, "data.user") &&
      get(res, "data.brands")
    ) {
      if (path === URLS.login) window.location = URLS.home;
      dispatch(setUserData(res.data.user));
    } else {
      window.localStorage.removeItem("token");
      dispatch(setAppLoading(false));
      dispatch(setUserData(null));
      if (path !== URLS.login) window.location = URLS.login;
    }
  }

  return (
    <>
      {loading ? (
        <div
          className="is-flex is-justify-content-center is-align-items-center"
          style={{ width: "100vw", height: "100vh" }}
        >
          <Spin
            size="large"
            indicator={<LoadingOutlined />}
            tip={
              <Title className="m-0 mt-2" level={4}>
                Authenticating...
              </Title>
            }
          />
        </div>
      ) : UserData ? (
        <Main />
      ) : (
        <Login />
      )}
    </>
  );
};
